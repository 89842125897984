import { Favorite, FavoriteBorder } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import React, { ReactElement, useEffect } from "react";
import { Link } from "react-router-dom";

import FullAsset from "../../components/dialogs/full-asset";
import Dropdawn from "../../components/dropdawn/dropdawn";
import MediaTypeIcon from "../../components/media-format/media-type-icon";
import UserRole from "../../components/user-role/user-role";
import { AppContext } from "../../context/app-context";
import {
  getDirectusAssetsPath,
  useDirectus,
} from "../../providers/DirectusProvider";
import Asset from "../../services/asset";
import { AssetType } from "../../types/assets";
import { Flatten, flatten } from "../../utils/flatten";

import useDetailPage from "./hooks/useDetailPage";
import ActivityFeed from "./parts/activity-feed";
import PDFViewer from "./parts/pdf-viewer";
import Tags from "./parts/tags";
import Title from "./parts/title";

export default function DetailPage(): ReactElement {
  const {
    asset,
    favorite,
    type,
    currentOffset,
    localState,
    selectedVersion,
    filedata,
    stackVersions,
    setStackVersions,
    handleChangeVersions,
    setFavorite,
    setAsset,
    getSiblingAsset,
  } = useDetailPage();

  const [modal, setModal] = React.useState<boolean>(false);
  const [messageNavigation, setMessageNavigation] =
    React.useState<boolean>(false);
  const [renameModalState, setRenameModalState] =
    React.useState<boolean>(false);
  const [assetName, setAssetName] = React.useState<string>();
  const [assetCategory, setAssetCategory] = React.useState<number>();
  const [metadataSwitcher, setMetadataSwitcher] =
    React.useState<boolean>(false);
  const { category } = React.useContext(AppContext);
  const { directus } = useDirectus();
  const { renameAssetById, changeCategoryById } = Asset(directus);
  const isMetedata = !!Object.keys(filedata?.metadata || {}).length;

  // Collect categories structure
  const categoriesItems = [];

  categoriesItems.push(
    <MenuItem key="default" value="">
      <em>None</em>
    </MenuItem>
  );

  flatten(category).map((item: Flatten) => {
    categoriesItems.push(
      <MenuItem className="text-[12px]" key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    );

    if (item.children) {
      item.children.map((family: Flatten) => {
        categoriesItems.push(
          <MenuItem
            className="text-[12px] pl-[30px]"
            key={family.id}
            value={family.id}
          >
            {family.name}
          </MenuItem>
        );

        if (family.children) {
          family.children.map((product: Flatten) => {
            categoriesItems.push(
              <MenuItem
                className="text-[12px] pl-[45px]"
                key={product.id}
                value={product.id}
              >
                {product.name}
              </MenuItem>
            );
          });
        }
      });
    }
  });

  function getMetadataCollection(metadata: any): any {
    const rowsArray = [];

    if (metadata) {
      for (const key in metadata) {
        const subObj = metadata[key];

        if (typeof metadata[key] == "object") {
          rowsArray.push({ caption: true, name: key });
        }

        for (const subKey in subObj) {
          rowsArray.push({
            caprion: false,
            name: `${subKey}: ${subObj[subKey]}`,
          });
        }
      }
    }

    return rowsArray;
  }

  useEffect(() => {
    setAssetName(asset?.name);
    setAssetCategory(asset?.category_id);
  }, [asset, renameModalState]);

  const handleToggleModal = (): void => {
    setModal(!modal);
  };

  const handleChangeToggleModal = (): void => {
    setRenameModalState(!renameModalState);
  };

  const handleChangeAssetName = async (): Promise<void> => {
    if (asset && assetName) {
      await renameAssetById(asset.id, assetName);

      setAsset({
        ...asset,
        name: assetName,
      });
    }

    setRenameModalState(!renameModalState);
  };

  const handleChangeCategory = async (e: SelectChangeEvent): Promise<void> => {
    setAssetCategory(parseInt(e.target.value));

    if (asset) {
      await changeCategoryById(asset.id, parseInt(e.target.value));
    }
  };

  const renderFormat = (param: string): ReactElement => {
    switch (param) {
      case AssetType.pdf:
        return (
          <PictureAsPdfIcon
            onClick={handleToggleModal}
            style={{ color: "#DD2127" }}
            className="text-[300px] cursor-zoom-in"
          />
        );
      case AssetType.psd:
        return (
          <FileOpenOutlinedIcon
            style={{ color: "#DD2127" }}
            className="text-[300px]"
          />
        );
      case AssetType.mp4:
        return (
          <video
            className="w-full"
            controls
            src={`${getDirectusAssetsPath(directus)}/${asset?.image}`}
          />
        );
      case AssetType.jpeg:
      case AssetType.png:
        return (
          <img
            onClick={handleToggleModal}
            className="inline-block w-full h-full max-w-[100%] max-h-[700px] object-contain cursor-zoom-in"
            src={`${getDirectusAssetsPath(directus)}/${asset?.image}?width=700`}
          />
        );
      default:
        return (
          <SearchOffIcon
            style={{ color: "#DD2127" }}
            className="text-[300px]"
          />
        );
    }
  };

  return (
    <div className="flex flex-row gap-[25px] ml-[45px] relative">
      <div className="absolute left-15 top-15 cursor-pointer z-1">
        <ArrowBackIosIcon />
        <div className="ml-[-5px] inline-block">
          <Link to={`/files`}>Back</Link>
        </div>
      </div>
      <div className="shadow rounded-[15px] p-50 bg-white gap-[20px] basis-[100%] flex-wrap flex max-w-full">
        <div className="flex flex-row flex-wrap w-full">
          <div className="w-[50%] text-right flex">
            <h2
              style={{ color: "#DD2127" }}
              className="text-[36px] whitespace-nowrap overflow-x-hidden text-ellipsis leading-tight"
            >
              {asset?.name}
            </h2>
            <UserRole>
              <IconButton
                onClick={handleChangeToggleModal}
                className="ml-10 w-[45px]"
                size="small"
              >
                <ModeEditOutlineOutlinedIcon fontSize="small" />
              </IconButton>
            </UserRole>
          </div>
          <div className="w-[50%] text-right">
            <div className="ml-[30px] text-[18px] inline-block">
              <ArrowBackIosIcon
                onClick={() => {
                  if (currentOffset) getSiblingAsset(currentOffset - 1);
                }}
                className="mr-[15px] cursor-pointer"
              />
              Next Assets
              <ArrowForwardIosIcon
                onClick={() => {
                  if (currentOffset) getSiblingAsset(currentOffset + 1);
                }}
                className="ml-[20px] cursor-pointer"
              />
            </div>
          </div>
        </div>
        <div>
          {localState?.stack?.length && (
            <FormControl style={{ minWidth: 150 }} size="small">
              <InputLabel className="text-[14px]">Stack versions</InputLabel>
              <Select
                className="border-0 rounded-[15px] text-[14px] text-left mr-15 max-w-full"
                onChange={handleChangeVersions}
                value={selectedVersion}
                label="Stack versions"
              >
                {localState.stack.map((item: any, index: number) => (
                  <MenuItem
                    className="max-w-[800px] block"
                    key={item.id}
                    value={item.id}
                  >
                    <Tooltip title={item.name} followCursor>
                      <div className="truncate">
                        <MediaTypeIcon
                          asset={item}
                          classes="text-[25px] max-w-[25px] max-h-[25px] mr-10 object-contain inline-block"
                        />
                        v.{index + 1}: {item.name}
                      </div>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        <div className="flex w-full">
          <div
            className={`${
              messageNavigation ? "w-2/5" : "w-1/2"
            } bg-slate-200 relative flex justify-center items-center hover:bg-slate-400 group hover:is-hover`}
          >
            <Checkbox
              className="absolute right-20 top-20 bg-white z-10"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFavorite(event.target.checked);
                (async function () {
                  if (asset?.id) {
                    await directus
                      ?.items("metaphore_assets")
                      .updateOne(asset?.id, {
                        favorite: event.target.checked,
                      });
                  }
                })();
              }}
              checked={favorite || false}
              icon={<FavoriteBorder />}
              checkedIcon={<Favorite />}
            />
            {/* <IconButton 
                            className="p-[9px] bg-white absolute right-[20px] top-[70px] z-10" 
                            onClick={handleToggleModal}
                            size="large"
                        >
                            <ZoomInIcon />
                        </IconButton> */}
            {asset && (
              <Dropdawn
                stackVersions={stackVersions}
                setStackVersions={setStackVersions}
                asset={asset}
                fileType={type}
                classes="mt-6 absolute right-20 top-[65px] p-1 bg-white rounded-[25px] z-1"
              />
            )}
            {!!type && renderFormat(type)}
          </div>
          <div className={`${messageNavigation ? "w-3/5" : "w-1/2"} ml-[50px]`}>
            <div className="flex">
              <div className={`${messageNavigation ? "w-1/3" : "w-full"}`}>
                <h2 className="text-[24px] font-bold mb-[40px] text-slate-600">
                  Asset Details
                </h2>
                <div className="text-16 mb-[40px] break-all">
                  <Title>TITEL</Title>
                  {asset?.name}
                </div>
                <div className="text-16 mb-[40px] break-all">
                  <Title>Date uploaded</Title>
                  {asset?.date_created &&
                    format(
                      new Date(asset?.date_updated || asset?.date_created),
                      "dd-MM-yyyy"
                    )}
                </div>
                <div className="text-16 mb-[40px] break-all">
                  <Title>MASTER AND ADAPTION IDs</Title>
                  {asset?.master_id}
                  <br />
                  {asset?.adaption_id}
                </div>
                {asset?.project_id && (
                  <div className="text-16 mb-[40px] break-all">
                    <Title>Project Number</Title>
                    {asset.project_id}
                  </div>
                )}
                {asset?.content_amount && (
                  <div className="text-16 mb-[40px] break-all">
                    <Title>Content Amount</Title>
                    {asset.content_amount} {asset.unit}
                  </div>
                )}
                <div className="text-16 mb-[40px]">
                  <Title>Asset Status</Title>
                  {asset?.status ? (
                    <Chip label={asset?.status.toUpperCase()} />
                  ) : (
                    "None"
                  )}
                </div>
                <div className="text-16 mb-[40px]">
                  <Title>Asset Type</Title>
                  {asset?.asset_types ? (
                    <Chip label={asset?.asset_types.toUpperCase()} />
                  ) : (
                    "None"
                  )}
                </div>
                <UserRole>
                  <div className="text-16 mb-[40px]">
                    <Title>Category</Title>
                    <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
                      <Select
                        className="border-0 rounded-[15px] text-[14px] text-left m-0"
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={
                          assetCategory
                            ? (assetCategory as unknown as string)
                            : ""
                        }
                        onChange={handleChangeCategory}
                      >
                        {categoriesItems}
                      </Select>
                    </FormControl>
                  </div>
                </UserRole>
                {isMetedata && (
                  <div className="text-16 mb-[40px]">
                    <Title>
                      Meta data
                      <IconButton
                        onClick={() => setMetadataSwitcher(!metadataSwitcher)}
                        className="ml-5 mt-[-5px]"
                        size="small"
                      >
                        {metadataSwitcher ? (
                          <ExpandCircleDownOutlinedIcon />
                        ) : (
                          <ExpandCircleDownOutlinedIcon className="-rotate-90" />
                        )}
                      </IconButton>
                    </Title>
                    <TableContainer
                      style={{ display: metadataSwitcher ? "block" : "none" }}
                      className="w-full"
                      component={Paper}
                    >
                      <Table aria-label="caption table" size="small">
                        <TableBody>
                          {getMetadataCollection(filedata?.metadata as any).map(
                            (o: any) => (
                              <TableRow key={o.name}>
                                <TableCell
                                  className={
                                    o.caption
                                      ? "capitalize bg-slate-100"
                                      : "pl-[30px]"
                                  }
                                  component={o.caption ? "th" : undefined}
                                >
                                  {o.name}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
                {filedata?.tags && <Tags tags={filedata.tags} />}
              </div>
              <div
                style={{
                  boxShadow: messageNavigation
                    ? "-7px 7px 10px #c0c0c06e"
                    : "none",
                }}
                className={`${
                  messageNavigation
                    ? "w-2/3 border-slate-300 border-l-1"
                    : "w-0"
                } pl-30 ml-30 relative`}
              >
                <Tooltip title="Comments">
                  <IconButton
                    size="large"
                    className={`${
                      messageNavigation ? "bg-slate-100" : ""
                    } top-0 right-0 p-10 absolute flex-col rounded-10`}
                    onClick={() => setMessageNavigation(!messageNavigation)}
                  >
                    {messageNavigation ? (
                      <CloseIcon />
                    ) : (
                      <MessageOutlinedIcon />
                    )}
                  </IconButton>
                </Tooltip>
                {messageNavigation && (
                  <>
                    <h2 className="text-[24px] font-bold mb-[20px] text-slate-600">
                      Activity Feed
                    </h2>
                    {asset?.id && <ActivityFeed assetId={asset.id} />}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FullAsset isOpen={modal} onClose={handleToggleModal}>
        {AssetType.pdf === type ? (
          <PDFViewer />
        ) : (
          <img
            className="inline-block object-contain"
            src={`${getDirectusAssetsPath(directus)}/${
              asset?.image
            }?width=2000`}
          />
        )}
      </FullAsset>
      <Dialog open={renameModalState} onClose={handleChangeToggleModal}>
        <DialogTitle>Change asset name</DialogTitle>
        <DialogContent className="min-w-[300px]">
          <TextField
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setAssetName(e.target.value)
            }
            autoFocus
            focused
            margin="dense"
            type="text"
            fullWidth
            variant="standard"
            placeholder="Enter asset name"
            defaultValue={assetName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeToggleModal}>Cancel</Button>
          <Button
            disabled={!assetName?.replace(/\s/g, "").length}
            onClick={handleChangeAssetName}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
