import { DefaultItem, ID, UserItem } from "@directus/sdk";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import saveAs from "file-saver";
import JSZip from "jszip";
import React, { ReactElement, useState } from "react";

import { AppContext } from "../../context/app-context";
import {
  getDirectusAssetsPath,
  useDirectus,
} from "../../providers/DirectusProvider";
import Asset from "../../services/asset";
import { MetaphoreAssets } from "../../types/assets";
import { MetaphoreLanguageRegions } from "../../types/language-regions";
import CollectionModal from "../collection-modal/collection-modal";
import UISelect from "../select/select";
import InputText from "../text/input-text";

interface Fields {
  field?: string | null | undefined;
  meta?:
    | {
        options?: any;
      }
    | null
    | undefined;
}

type Props = {
  collectionAssets?: boolean;
};

export default function Filter(props: Props): ReactElement {
  const { collectionAssets } = props;

  // const [toggle, setToggle] = useState<boolean>(false);
  const [action, setAction] = useState<boolean>(false);
  const [fields, setFields] = useState<Fields[] | undefined>();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [users, setUsers] = useState<DefaultItem<UserItem<unknown>>[]>([]);
  const [firstItem, setFirstItem] = useState<
    DefaultItem<MetaphoreAssets> | undefined
  >();
  const [language, setLanguage] = useState<MetaphoreLanguageRegions[]>([]);
  const { directus } = useDirectus();
  const { getAssetById } = Asset(directus);
  const {
    assets,
    selectedFiles,
    selectedFilesAll,
    sorting,
    collectionId,
    filter,
    setSelectedFiles,
    setSelectedFilesAll,
    setSorting,
    setFilter,
    setAssets,
  } = React.useContext(AppContext);

  React.useEffect(() => {
    (async () => {
      const usersArr: any = [];
      const languageArr: any = [];

      const fields = await directus?.fields.readMany("metaphore_assets");
      const language = await directus
        ?.items("metaphore_language_regions")
        .readByQuery();
      const users = await directus?.users.readByQuery();

      users?.data?.map((user) => {
        usersArr.push({
          text:
            user.first_name && user.last_name
              ? `${user.first_name} ${user.last_name}`
              : user.email,
          value: user.id,
        });
      });

      language?.data?.map((lang) => {
        languageArr.push({
          text: lang.name,
          value: lang.id,
        });
      });

      if (fields?.data) {
        setFields(fields.data);
        setUsers(usersArr);
        setLanguage(languageArr);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (selectedFiles[0]) {
        setFirstItem((await getAssetById(selectedFiles[0])) ?? undefined);
      }
    })();
  }, [selectedFiles]);

  const getListType = (fields: Fields[], type: string): any => {
    return fields.find((item) => item.field === type)?.meta?.options.choices;
  };

  const handleDownloadFiles = async (): Promise<void> => {
    const zip = new JSZip();

    await Promise.all(
      selectedFiles.map(async (id) => {
        const file = assets?.find((asset) => asset.id == id);

        if (file) {
          const type = await Asset(directus).getFileType(file.image);
          const imageUrl = `${getDirectusAssetsPath(directus)}/${
            file.image
          }?download`;
          const imageBlob = await fetch(imageUrl).then((response) =>
            response.blob()
          );

          zip.file(
            `${file.name}.${type?.substring(type?.indexOf("/") + 1)}`,
            imageBlob,
            { binary: true }
          );
        }
      })
    );

    zip
      .generateAsync({ type: "blob" })
      .then((content) => saveAs(content, "metahub-archive"));
  };

  const handleArchiveFiles = async (): Promise<void> => {
    if (!selectedFiles?.length) return;

    await directus
      ?.items("metaphore_assets")
      .updateMany(selectedFiles, { status: "archived" });

    setAction(!action);
  };

  const handleSelectAll = (): void => {
    if (!assets) return;

    setSelectedFilesAll(!selectedFilesAll);
    setSelectedFiles(assets.map((asset) => asset.id.toString()));

    if (selectedFilesAll) {
      setSelectedFiles([]);
    }
  };

  const handleDeteleFromCollection = (): void => {
    if (confirm("Are you sure?")) {
      selectedFiles.map(async (item: any): Promise<void> => {
        const assetCollections = await directus
          ?.items("metaphore_assets")
          .readOne(item as number);
        const junctionCollections = await directus
          ?.items("metaphore_assets_metaphore_collections")
          .readMany(assetCollections?.collection_id as ID[]);
        const updatedCollections = junctionCollections?.data?.filter(
          (item: any) => item?.metaphore_collections_id != collectionId
        );

        if (assetCollections?.id) {
          await directus?.items("metaphore_assets").updateOne(item, {
            collection_id: updatedCollections?.length
              ? updatedCollections
              : null,
          });

          setAssets((prev) => {
            return prev?.filter((item) => item.id !== assetCollections.id);
          });
        }
      });
    }
  };

  const handleSortChange = (e: SelectChangeEvent): void => {
    if (e.target.value) {
      setSorting([e.target.value as string]);
    } else {
      setSorting([]);
    }
  };

  const handleSearchIds = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value
        ? {
            _contains: event.target.value,
          }
        : undefined,
    });
  };

  return (
    <div className="flex w-full mb-[25px] flex-wrap">
      <div className="block basis-[60%]">
        {fields && (
          <>
            <UISelect
              className="mr-15 mb-15"
              label="Asset Types"
              name="asset_types"
              list={getListType(fields, "asset_types")}
            />
            <UISelect
              className="mr-15"
              label="Status"
              name="status"
              list={getListType(fields, "status")}
            />
            {/* <UISelect className="mr-15" label="Creator" name="user_created" list={users} /> */}
            {/* <UISelect className="mr-15" label="File Type" name="file_type" list={getListType(fields, 'file_type')} /> */}
            {/* {toggle && ( */}
            <>
              <UISelect
                className="mr-15"
                label="Metashot type"
                name="metashots_type"
                list={getListType(fields, "metashots_type")}
              />
              <UISelect
                className="mr-15 w-[175px]"
                label="Language & Regions"
                name="language_regions_id"
                list={language}
              />
              <InputText
                label="Master Id"
                name="master_id"
                onChange={handleSearchIds}
                prefix="MAS"
                type="number"
                width="130"
              />
              <InputText
                label="Adaption Id"
                name="adaption_id"
                onChange={handleSearchIds}
                prefix="AD-ID"
                type="number"
                width="150"
              />
              <InputText
                label="Project number"
                name="project_id"
                onChange={handleSearchIds}
                prefix="P"
                type="number"
                width="85"
              />
              {/* <UISelect className="mr-15" label="Rights" name="rights" /> */}
            </>
            {/* )} */}
          </>
        )}
        {/* <Link 
                    onClick={() => setToggle(!toggle)} 
                    underline="hover"
                    className="mt-10 inline-block cursor-pointer text-[14px] text-slate-500"
                >
                    {toggle ? 'Show less filters' : 'Show all filters'}
                </Link> */}
        <Link
          onClick={() => {
            setFilter({});
            setSorting(["-date_created"]);
          }}
          underline="hover"
          className="mt-10 ml-10 inline-block cursor-pointer text-[14px] text-slate-500"
        >
          Reset filter
        </Link>
      </div>
      <div className="block basis-[40%] text-right">
        <FormControlLabel
          sx={{
            ".MuiFormControlLabel-label": {
              fontSize: "14px",
              whiteSpace: "nowrap",
            },
          }}
          control={
            <Checkbox checked={selectedFilesAll} onChange={handleSelectAll} />
          }
          label="Select All"
        />
        <FormControl style={{ minWidth: 150 }} size="small">
          <InputLabel className="text-[14px]">Action</InputLabel>
          <Select
            className="border-0 rounded-[15px] text-[14px] text-left mr-15 mb-15"
            label="Action"
            open={action}
            onClick={() => setAction(!action)}
          >
            <MenuItem
              disabled={!selectedFiles?.length}
              onClick={handleDownloadFiles}
            >
              Download selected
            </MenuItem>
            <MenuItem
              disabled={!selectedFiles?.length}
              onClick={handleArchiveFiles}
            >
              Archive selected
            </MenuItem>
            <MenuItem
              disabled={!selectedFiles?.length}
              onClick={() =>
                collectionAssets
                  ? handleDeteleFromCollection()
                  : setOpenDialog(true)
              }
            >
              {collectionAssets
                ? "Remove from Collection"
                : "Add to Collections"}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ minWidth: 150 }} size="small">
          <InputLabel className="text-[14px]">Sort by</InputLabel>
          <Select
            className="border-0 rounded-[15px] text-[14px] text-left mr-15"
            label="Sort by"
            value={sorting.length ? sorting[0] : ""}
            onChange={handleSortChange}
          >
            <MenuItem value="-date_created">Default</MenuItem>
            <MenuItem value="-user_created">Last added by all</MenuItem>
            <MenuItem value="name">Name</MenuItem>
            {/* <MenuItem value="category">Category</MenuItem> */}
          </Select>
        </FormControl>
      </div>
      {firstItem && (
        <CollectionModal
          asset={firstItem}
          openModal={openDialog}
          closeModal={setOpenDialog}
          selectedAssets={selectedFiles}
        />
      )}
    </div>
  );
}
