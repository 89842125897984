import { InputAdornment, TextField } from "@mui/material";
import * as React from "react";

import { Options } from "../upload-modal";

type Props = {
  prefix?: string;
  label?: string;
  width?: string;
  placeholder?: string;
  name: string;
  required?: boolean;
  defaultValue?: string | undefined;
  className?: string | undefined;
  getOptions: Options | undefined;
  setOption: React.Dispatch<React.SetStateAction<Options | undefined>>;
};

export default function InputText(props: Props): React.ReactElement {
  const {
    label,
    width,
    defaultValue,
    className,
    setOption,
    getOptions,
    required,
    prefix,
    placeholder,
    name,
  } = props;

  const [value, setValue] = React.useState(defaultValue || "");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setOption({
      ...getOptions,
      [name]: prefix ? prefix + event.target.value : event.target.value,
    });

    setValue(event.target.value);
  };

  return (
    <TextField
      onChange={handleChange}
      name={name}
      label={label}
      className={className}
      variant="outlined"
      id="filled-start-adornment"
      size="small"
      value={value}
      placeholder={placeholder}
      sx={{
        width: `${width ?? "150"}px`,
        ".MuiInputBase-root": {
          borderRadius: "15px",
        },
        ".MuiInputBase-input, .MuiInputLabel-root, .MuiTypography-root": {
          fontSize: "12px",
        },
      }}
      InputProps={
        (prefix && {
          startAdornment: (
            <InputAdornment position="start">{prefix}</InputAdornment>
          ),
        }) ||
        undefined
      }
    />
  );
}
