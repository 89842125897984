import { InputAdornment, OutlinedInput } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import * as React from "react";

type Props = {
  label: string;
  name: string;
  defaultValue?: string | undefined;
  className?: string | undefined;
  type?: string;
  prefix?: string;
  width?: string;

  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function InputText(props: Props): React.ReactElement {
  const {
    label,
    defaultValue,
    width = 100,
    prefix,
    name,
    type = "text",
    onChange,
  } = props;

  const [value, setValue] = React.useState(defaultValue || "");

  return (
    <FormControl className="custom-text-input" size="small" variant="outlined">
      <InputLabel className="text-[14px]">{label}</InputLabel>
      <OutlinedInput
        style={{ width: `${width}px` }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
          setValue(e.target.value);
        }}
        type={type}
        className="border-0 rounded-[15px] text-[14px] mr-15 pr-0"
        label={label}
        name={name}
        value={value}
        startAdornment={
          <InputAdornment position="start">{prefix}</InputAdornment>
        }
      />
    </FormControl>
  );
}
